import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import Vero from 'components/forms/vero';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';

import './styles/request-info.scss';

export default function RequestInfo() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "request-info" } }) {
        edges {
          node {
            slug
            wordpress_id
            acf {
              hero {
                signup {
                  legal_text
                  hide_signup_component
                }
                title
                sub_title
              }
              dynamic_text_block {
                copy
              }
            }
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoastData = data.allWordpressPage.edges[0].node.yoast_head_json;
  const hero = pageContext.hero;

  return (
    <Layout
      showInitFields={ false }
      legalText={ hero.signup.legal_text }
      showModalButton
    >
      <Yoast { ...yoastData } />
      <div className="request-info-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup { ...hero } />
        <section className="section" data-section="request-info-section">
          <div className="container">
            <div className="columns">

              <div className="column vero-column">
                <Vero
                  formType="requestInfo"
                  fullNameField
                  emailField
                  phoneField
                  industryField
                  workField
                  employeesField
                  messageField
                  showIcons
                  submitBtnText="Send Message"
                />
              </div>

              <div className="column is-two-fifths">
                <div>
                  <h2 className="is-size-3">Need Support / Help?</h2>
                  <a href="https://help.wheniwork.com">
                    Submit a support ticket
                  </a>
                </div>
                <hr className="has-margin-left-0" />
                <div>
                  <h2 className="is-size-3">Snail Mail</h2>
                  <h3>When I Work, Inc.</h3>
                  <address>420 North 5th St. Suite 500 Minneapolis,MN 55401 U.S.A.</address>
                  <strong>support@wheniwork.com</strong>
                </div>
              </div>
              
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
